// src/components/AnalysisCommentTable.js
import React, { useEffect, useState } from 'react';
import { PrimaryTable, Tag, Tooltip, Button, Space} from 'tdesign-react';

const AnalysisCommentTable = ({ comments, handleAnalysis, buttonStatus, currentTaskId, activeMode }) => {
    const getButtonLabel = (row) => {
        if (buttonStatus[row.comment_id] === 'testing') {
            return '正在测试';
        }
        return row.意向客户 ? '再次测试' : '测试单条';
    };

    const column_simple = [
        { title: '序号', colKey: 'index', width: "90px" },
        { title: '评论时间', colKey: '评论时间', width: "120px" },
        { title: '用户昵称', colKey: '用户昵称', width: "150px" },
        { title: '用户省份', colKey: 'IP地址', width: "100px" },
        { title: '评论内容', colKey: '评论内容', width: "300px" },
        { title: '分析理由', colKey: '分析理由', width: "300px" },
        { title: '意向客户', colKey: '意向客户', width: "100px" },
        // {
        //     title: '操作',
        //     colKey: '操作',
        //     width: "120px",
        //     cell: ({ row }) => (
        //         <Tooltip
        //             content="测试填入的产品/服务名称分析效果"
        //             destroyOnClose
        //             showArrow
        //             theme="default"
        //         >
        //             <Button
        //                 theme="primary"
        //                 variant="base"
        //                 onClick={() => handleAnalysis(currentTaskId, row.comment_id)}
        //                 disabled={buttonStatus[row.comment_id] === 'testing'}
        //             >
        //                 {getButtonLabel(row)}
        //             </Button>
        //         </Tooltip>
        //
        //     ),
        // },
    ]
    const column_full = column_simple
        .filter(column => column.colKey !== '分析理由').concat([
            { title: '情绪分析', colKey: '情绪分析', width: "120px" },
            { title: '提及产品', colKey: '提及产品', width: "180px" },
            { title: '满意点', colKey: '满意点', width: "150px" },
            { title: '不满点', colKey: '不满点', width: "150px" },
            { title: '疑问点', colKey: '疑问点', width: "150px" },
        ])

    const [columns, setColumns] = useState(column_simple)

    useEffect(() => {
        if (activeMode === '1') setColumns(column_simple)
        else if (activeMode === '2') setColumns(column_full)
        // eslint-disable-next-line
    }, [activeMode])

    return (
        <Space direction="vertical">
            <PrimaryTable
                style={{ marginBottom: "10px", overflowX: 'auto', maxWidth: '80vw' }}
                columns={columns}
                data={comments}
                rowKey="comment_id"
                maxHeight={1000}
                size="medium"
                tableLayout="fixed"
                verticalAlign="middle"
                resizable={true}
                hover
                cell={({ colKey, row }) => {
                    if (colKey === '操作') {
                        return (
                            <Tooltip
                                content="测试填入的产品/服务名称分析效果"
                                destroyOnClose
                                showArrow
                                theme="default"
                            >
                                <Button
                                    theme="primary"
                                    variant="base"
                                    onClick={() => handleAnalysis(row.task_id, row.comment_id)}
                                    disabled={buttonStatus[row.comment_id] === 'testing'}
                                >
                                    {getButtonLabel(row)}
                                </Button>
                            </Tooltip>
                        );
                    }
                    if (colKey === '意向客户') {
                        return (
                            <Tag variant="outline" theme={row[colKey] === "是" ? "success" : "default"}>
                                {row[colKey]}
                            </Tag>
                        );
                    }
                    return row[colKey] || '';
                }}
            />
        </Space>
    );
};

export default AnalysisCommentTable;
