import ReportPieChartComponent from "../components/ReportPieChartComponent";
import ReplySatisfactionPoints from "../components/ReplySatisfactionPoints";
import Space from "tdesign-react/es/space/Space";
import { useEffect, useState } from "react";
import { Loading } from "tdesign-react";

const ReviewReport = ({ taskId, commentList, PieData, detailData, pieDataLoading }) => {

    const [detailDataLoading, setDetailDataLoading] = useState(false)

    useEffect(() => {
        if (!detailData) {
            setDetailDataLoading(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (detailData) { setDetailDataLoading(false) }
    }, [detailData])

    return (
        <Space direction="vertical" style={{ width: '80vw' }}>
            <Loading loading={pieDataLoading} text="拼命加载中..." size="small">
                <ReportPieChartComponent commentList={commentList} taskId={taskId} PieDataList={PieData} /></Loading>
            <Loading loading={detailDataLoading} text="拼命加载中..." size="small">
                <ReplySatisfactionPoints commentList={commentList} taskId={taskId} detailDataList={detailData} /></Loading>
        </Space>
    );
}
export default ReviewReport;