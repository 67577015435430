import React, {useState, useEffect} from 'react';
import {CloseCircleFilledIcon} from 'tdesign-icons-react';
import {
    Table,
    Pagination,
    Tag,
    Space,
    DateRangePicker,
    Select,
    Col,
    Row,
    Tooltip,
    Divider,
    Dropdown, MessagePlugin
} from 'tdesign-react';
import type {DateRangePickerProps} from 'tdesign-react';
import dayjs from 'dayjs';
import {getMarketListByTaskIdApi, updateMarketingUserApi} from "../api/api";
import QRCode from "qrcode.react";

const IntentorTable = ({userLinkList, total, curMarketingTaskId, taskId, platform}) => {
    const [userRegion, setUserRegion] = useState('全选');//选中的地区
    const [dateRange, setDateRange] = useState([]);//日期选项
    const [regionOptions, setRegionOptions] = useState([]);//地区选项
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(total);//筛选的表长度
    const [userList, setUserList] = useState([]);//展示的表
    const [sumData, setSumData] = useState([]);//总表，只根据userLinkList变化
    const [isUserListUpdated, setIsUserListUpdated] = useState(false);//是否被用户手动改过意向表

    const columns = [
        {title: '意向用户列表', colKey: 'nickname', width: '200px'},
        {title: '用户地区', colKey: 'region', width: '120px'},
        {title: '评论时间', colKey: 'time', width: '120px'},
        {title: '评论内容', colKey: 'comment', width: 'auto'},
        {
            title: '是否私信', colKey: 'privateMessage',
            cell: ({row}) => {
                return (
                    row.privateMessage === '已私信' ?
                        <Tag variant="outline" theme="success">
                            <p>已私信</p></Tag> :
                        <Dropdown
                            options={[{content: '移出意向列表', value: 1,}, {content: '设置为已私信', value: 2,}]}
                            onClick={(option) => clickHandler(option,row )}>
                            <Tag variant="outline" theme="warning">
                                <p>{row.privateMessage !== null ? row.privateMessage : '未私信'}</p>
                            </Tag>
                        </Dropdown>
                )
            }


        }
    ];

    useEffect(() => {
        if (userLinkList && userLinkList.length !== 0) {
            // userLinkList.forEach(item=>console.log(item.私信结果))
            const formattedData = formateData(userLinkList)
            setSumData(formattedData);

            const regionCount = formattedData.reduce((acc, item) => {
                acc[item.region] = (acc[item.region] || 0) + 1;
                return acc;
            }, {});

            const sortedRegions = Object.keys(regionCount).sort((a, b) => {
                if (a === "IP未知") return 1;
                if (b === "IP未知") return -1;
                return regionCount[b] - regionCount[a];
            });

            const formattedRegions = sortedRegions.map(region => ({
                label: `${region}-${regionCount[region]}人`,
                value: region
            }));
            setRegionOptions([{label: '全部地区', value: '全选'}, ...formattedRegions]);

            setTotalItems(formattedData.length);
            const start = (currentPage - 1) * pageSize;
            const end = start + pageSize;
            setUserList(formattedData.slice(start, end));
        } else {
            setUserList([])
        }
        setDateRange([]);
    }, [userLinkList]);

    useEffect(() => {//页码有变化立即重新获取
        if ((curMarketingTaskId&&curMarketingTaskId.length > 0 && curMarketingTaskId.includes(taskId))||isUserListUpdated) {
            getUserList()
        }
    }, [curMarketingTaskId, currentPage, pageSize,isUserListUpdated]);

    useEffect(() => {
        setCurrentPage(1);
    }, [taskId]);

    const formateData=(data)=>{//把后端的user_link_list格式化
        return  data.map((item, index) => ({
            key: index,
            nickname: platform === 'xhs'
                ?
                <Tooltip content={
                    <div align={"center"}>
                        <span style={{fontSize: "18px"}}>小红书扫码私信</span>
                        <Divider layout={"horizontal"} style={{margin: "10px 5px"}}/>
                        <QRCode value={item.user_link} size={128}/></div>
                } theme="light">
                    <a href={item.user_link} target="_blank" rel="noreferrer">{item.用户昵称}</a>
                </Tooltip>
                : <a href={item.user_link} target="_blank" rel="noreferrer">{item.用户昵称}</a>,
            region:
                item.IP地址 === "" || item.IP地址 === "IP未知" ? "IP未知" : item.IP地址,
            comment: <a href={item.内容链接} target="_blank" rel="noreferrer">{item.评论内容}</a>,
            privateMessage: item.私信结果,
            time: item.评论时间,
            comment_id:item.comment_id
        }));
    }

    const getUserList = async () => {//重新获取当前页码的userLinkList
            let offset = (currentPage - 1) * pageSize
           await getMarketListByTaskIdApi(taskId, offset, pageSize)
                .then(data => {
                    if(data&&data.user_link_list){
                        const formattedData = formateData(data.user_link_list)
                        setUserList(formattedData)
                    }
                }).catch(err => console.error(err))
    }

    const handlePageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setPageSize(newPageSize);
        const start = (newPage - 1) * newPageSize;
        const end = start + newPageSize;
        filterData(userRegion, dateRange, start, end);
    };

    const handleRegionChange = (value) => {
        setUserRegion(value);
        setCurrentPage(1);
        filterData(value, dateRange, 0, pageSize);
    };

    const handleRangeChange: DateRangePickerProps['onChange'] = (value) => {
        setDateRange(value);
        setCurrentPage(1);
        filterData(userRegion, value, 0, pageSize);
    };

    const filterData = (region, range, start, end) => {
        const [startDate, endDate] = range;
        const filteredData = sumData.filter(item => {
            const itemDate = new Date(item.time);
            const isRegionMatch = region === '全选' || item.region === region;
            const isDateMatch = !range.length || (itemDate >= new Date(startDate) && itemDate <= new Date(endDate));
            return isRegionMatch && isDateMatch;
        });
        setTotalItems(filteredData.length);
        setUserList(filteredData.slice(start, end));
    };

    const clickHandler=async(option,row)=>{//“未私信”客户的处理
        let back_data={
            task_id:taskId,
            platform,
            comment_id:row.comment_id
        }
        if(option.value===1){
            back_data={
                ...back_data,
                intent_customer:'否'
            }
        }else{
            back_data={
                ...back_data,
                market_result:'已私信'
            }
        }
        await updateMarketingUserApi(back_data).then(data=>{
            if(data.status===200){
                getUserList().then(data=>{
                    setIsUserListUpdated(true);
                    MessagePlugin.success('操作成功')
                }
                )
            }
        })
    }

    const customClearIcon = <CloseCircleFilledIcon style={{color: 'blue'}}/>;
    return (
        <Space direction='vertical' style={{width: "98%"}}>
            <Row direction='horizontal' align='center' style={{width: "100%"}}>
                <Col flex="none">
                    <Space align='center'>
                        {/*<h2>筛选条件</h2>*/}
                        <Select
                            style={{width: "150px"}}
                            value={userRegion}
                            options={regionOptions}
                            onChange={handleRegionChange}
                            placeholder="选择地区"
                        />
                        <DateRangePicker
                            style={{width: "15vw"}}
                            mode="month"
                            value={dateRange}
                            clearable
                            allowInput
                            onChange={handleRangeChange}
                            placeholder="时间范围"
                            suffixIcon={customClearIcon}
                            disableDate={(date) => dayjs(date).isAfter(dayjs(), 'day')}
                        />
                    </Space>
                </Col>
                <Col flex="auto">
                    <Pagination
                        style={{marginLeft: "10px"}}
                        total={totalItems}
                        pageSize={pageSize}
                        current={currentPage}
                        onChange={(pageInfo) => handlePageChange(pageInfo.current, pageInfo.pageSize)}
                        showJumper
                        pageSizeOptions={[10, 20]}
                    />
                </Col>
            </Row>
            <Table columns={columns} data={userList} bordered style={{width: "100%"}}/>
        </Space>
    );
};

export default IntentorTable;
