import React from 'react';
import {Collapse, Space, Tag} from 'tdesign-react';
import CollapsePanel from "tdesign-react/es/collapse/CollapsePanel";

const ReplySatisfactionPoints = ({detailDataList}) => {
    const [satisfactionData, questionData, dissatisfactionData] = detailDataList || [[], [], []];

    // 过滤出非公关建议的数据
    const filterNonPRPoints = (data) => {
        return data.filter(item => item.comments.length > 0);
    };

    // 过滤出公关建议的数据
    const filterPRPoints = (data) => {
        return data.filter(item => item.comments.length === 0);
    };

    const renderCollapsePanels = (data, backgroundColor) => {
        return data.map((point, index) => (
            <CollapsePanel key={index + 1} header={point.title}>
                <div style={{backgroundColor, padding: '5px', borderRadius: '3px'}}>
                    {point.comments.map((comment, idx) => (
                        <p key={idx}>
                            {comment}
                        </p>
                    ))}
                </div>
            </CollapsePanel>
        ));
    };

    const renderPublicRelationsSuggestion = (data, backgroundColor) => {
        const prSuggestions = data.map(item => item.title);
        if (prSuggestions.length === 0) return null;
        return (
            <CollapsePanel key="pr" header={<span style={{color: '#405DF9'}}>公关建议</span>}>
                <div style={{backgroundColor, padding: '5px', borderRadius: '3px'}}>
                    {prSuggestions.map((suggestion, index) => (
                        <p key={index}>
                            {suggestion}
                        </p>
                    ))}
                </div>
            </CollapsePanel>
        );
    };

    return (
        <div>
            <Space direction="vertical">
                <Space align="center">
                    <h2 style={{width: "70px"}}>满意点</h2>
                    <Space>
                        {satisfactionData.map((point, index) => (
                            <Tag key={index} theme="success" variant="light-outline" style={{cursor: 'pointer'}}>
                                {point.title}
                            </Tag>
                        ))}
                    </Space>
                </Space>
                <Collapse defaultActiveKey={['1']} borderless>
                    {renderCollapsePanels(filterNonPRPoints(satisfactionData), '#DFF0D8')}
                </Collapse>

                <Space align="center">
                    <h2 style={{width: "70px"}}>疑问点</h2>
                    <Space breakLine>
                        {filterNonPRPoints(questionData).map((point, index) => (
                            <Tag key={index} theme="primary" variant="light-outline" style={{cursor: 'pointer'}}>
                                {point.title}
                            </Tag>
                        ))}
                    </Space>
                </Space>
                <Collapse defaultActiveKey={['1']} borderless>
                    {renderCollapsePanels(filterNonPRPoints(questionData), '#CCE5FF')}
                    {renderPublicRelationsSuggestion(filterPRPoints(questionData), '#CCE5FF')}
                </Collapse>

                <Space align="center">
                    <h2 style={{width: "70px"}}>不满点</h2>
                    <Space breakLine>
                        {filterNonPRPoints(dissatisfactionData).map((point, index) => (
                            <Tag key={index} theme="warning" variant="light-outline" style={{cursor: 'pointer'}}>
                                {point.title}
                            </Tag>
                        ))}
                    </Space>
                </Space>
                <Collapse defaultActiveKey={['1']} borderless>
                    {renderCollapsePanels(filterNonPRPoints(dissatisfactionData), '#FFF3CD')}
                    {renderPublicRelationsSuggestion(filterPRPoints(dissatisfactionData), '#FFF3CD')}
                </Collapse>
            </Space>
        </div>
    );
};

export default ReplySatisfactionPoints;
