// src/components/AnalysisProgress.js
import React, {useEffect} from 'react';
import {Row, Col, Progress, Pagination, Button} from 'tdesign-react';

const AnalysisProgress = ({ progressLabel, progress, clientNum, commentsTotal, currentPage, pageSize, setCurrentPage, setPageSize,onReply,taskId }) => {

    useEffect(() => {
        setCurrentPage(1);
    }, [taskId]);

    return (
        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Col>
                {progressLabel}
            </Col>
            <Col flex="200px">
                <Progress
                    label
                    percentage={progress}
                    theme="line"
                    style={{ marginLeft: '10px', marginRight: '15px' }}
                />
            </Col>
            <Col>
                意向：{clientNum} 个，占比：{commentsTotal === 0 ? 0 : (clientNum / commentsTotal * 100).toFixed(0)} %
            </Col>
            {progress===100&&<Col style={{ marginLeft: '10px' }}>
                <Button onClick={()=>onReply(true)}>去私信</Button>
            </Col>}
            <Col flex="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                    style={{ width: '490px'}}
                    theme="simple"
                    total={commentsTotal}
                    current={currentPage}
                    pageSize={pageSize}
                    onChange={(event) => {
                        const newPage = event.current;
                        const newPageSize = event.pageSize;
                        setCurrentPage(newPage);
                        setPageSize(newPageSize);
                    }}
                    pageSizeOptions={[10, 20, 50]}
                />
            </Col>
        </Row>
    );
};

export default AnalysisProgress;
