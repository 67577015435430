// src/App.js
import React from 'react';
import AppRouter from './AppRouter';
import 'tdesign-react/es/style/index.css';

function App() {
    return <AppRouter />;
}

export default App;
